.wrapper {
  --size-img: 600px;
  position: relative;
  height: 0;
  overflow: hidden;
  grid-column: 1 / -1 !important;
  height: var(--size-img);
}

.wrapper iframe,
.wrapper object,
.wrapper embed, 
.wrapper lite-youtube, 
.player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none; 
  max-width: 100% !important;
}

@media (max-width: 1024px) {
  .container{
    --size-img: 440px;
  }
}
