.container {
  display: flex;
  flex-flow: column wrap;
  font: var(--font-h2-mobile-default);
  margin-top: 8px;
  gap: var(--spacing-24);

  --input-block: 0;
  --input-inline: 1.25rem
}

.textField {
  font: var(--font-h2-mobile-default);
  padding: var(--input-inline) var(--input-block) var(--input-inline) var(--input-block);
  width: 100%;
  height: 100%;
  resize: none;
  border: none;
  border-bottom: 2px solid;
  background: transparent;
  transition: 0.2s border-color ease-in-out;
}


.isLoading{
  border-color: var(--grey);
}

.labelContainer {
  position: relative;
}

.label {
  position: absolute;
  left: var(--input-block);
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  font: var(--font-h2-mobile-default);
  pointer-events: none;
  transition: 0.2s top ease-in-out, 0.2s font-size ease-in-out, color 0.2s ease-in-out;
  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: 1;
  transition: 0.2s opacity ease-in-out, color 0.2s ease-in-out;
}

.icon {
  display: flex;
  position: absolute;
  right: 0;
  transform: translateY(-50%);
  top: 50%;
  cursor: pointer;
}

.icon svg{
  --size-icon: 54px;
  width: var(--size-icon);
  height: var(--size-icon);
  transition: 0.2s transform ease-in-out, color 0.2s ease-in-out;
}

.labelTop {
  font: var(--font-h2-mobile-default);
  height: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 0;
}

.labelHide {
  opacity: 0;
  z-index: -1;
}

.dark .textField {
  border-color: var(--black);
}

.dark .textField,
.dark .label,
.dark .label svg {
  color: var(--black);
}

.light .textField,
.light .label,
.light .label svg, 
.light .icon {
  color: var(--white);
}

.dark .labelTop,
.light .labelTop {
  color: var(--grey);
}


@media (max-width: 1024px){
  .container, .label, .labelTop{
    font-size: 2rem;
  }
  .icon svg{
    --size-icon: 40px;
  }
}

@media (max-width: 608px) {
  .container, .label, .labelTop{
    font-size: 1.5rem;
  }
  .icon svg{
    --size-icon: 32px;
  }
}