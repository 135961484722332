.container{
  display: grid;
  grid-template-columns: var(--grid-template-desktop);
  min-height: 700px;
}

.titleContainer{
  display: grid;
  grid-column: 1 / 6 !important;
  grid-template-columns: 1fr  repeat(4,minmax(0,var(--size-row)));
  background: var(--yellow-500);
  align-items: flex-end;
  padding: 0;
}

.title{
  position: relative;
  grid-column: 2 / 6;
  display: inline-block;
  font: var(--font-h1-desktop-default);
  color: var(--black);
  text-transform: uppercase;
  font-weight: 800;
  padding: var(--spacing-80);
  height: fit-content
}

.icon{
  --size-icon: 24px;
  margin-left: 4px;
  height: var(--size-icon);
  width: var(--size-icon);
}

.heroContainer{
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  grid-column: 6 / full-end !important;
  background: var(--beige);
  padding: 0 !important;
}

.heroImg{
  position: relative;
  width: 100%;
  flex: 1 0 auto;
  background: var(--grey);
  clip-path: inset(0 0 0 0);
}

.hasYoutube{
  cursor: none;
}

.isDesktop{
  display: grid;
}

.isMobile{
  display: none;
}

@media (max-width: 1200px){
  .titleContainer{
    grid-column: 1 / 7 !important;
    grid-template-columns: 0;
  }
  .heroContainer{
    grid-column: 7 / full-end !important;
  }
  .searchBar{
    padding: var(--spacing-40) var(--spacing-40) var(--spacing-80);
  }
  .videoButton{
    top: var(--spacing-40);
    right: var(--spacing-40);
  }
}

@media (max-width: 1024px){
  .container{
    min-height: 550px;
  }
  .title{
    font: var(--font-h1-mobile-default);
    font-weight: 800;
    padding-left: var(--spacing-24);
  }
  .videoButton{
    --size-icon: 85px;
  
    top: var(--spacing-24);
    right: var(--spacing-24);
  }
  
  .videoLabel{
    font: var(--font-p3-mobile-default);
    font-weight: 800;
  }
}

@media (max-width: 768px){
  .titleContainer{
    grid-column: full-start / full-end !important;
    grid-template-columns: var(--grid-template-desktop);
  }
  .title{
    grid-column: full-start / 9;
  }
  .heroImg{
    height: 150px;
    grid-column: full-start / full-end;
  }

  .heroContainer{
    grid-column: full-start / full-end !important;
  }

  .isDesktop{
    display: none;
  }
  .isMobile{
    display: grid;
  }
}

@media (max-width: 608px) {
  .container{
    min-height: auto;
  }

  .title{
    padding-inline: var(--spacing-24);
  }
}

@media (max-width: 480px) {
  .title{
    grid-column: full-start / full-end;
  }
}
