.cursor {
  --size-icon: 126px;
  position: fixed;
  top: calc(var(--size-icon) * -1 / 2);
  left: calc(var(--size-icon) * -1 / 2);
  transform: translate(-50%, -50%);
  pointer-events: none;
  cursor: none;
}

.videoIcon{
  height: var(--size-icon);
  width: var(--size-icon);
  cursor: none;
}

.videoLabel{
  position: absolute;
  text-align: center;
  top: 47%;
  left: 55%;
  transform: translate(-50%,-50%);
  font: var(--font-p2-desktop-default);
  color: var(--yellow-500);
  font-weight: 800;
  z-index: 1;
  cursor: none;
}
