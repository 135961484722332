.container{
  position: relative;
  display: flex;
  flex-flow: column wrap;
  gap: var(--spacing-8);
}

.container:after{
  content: '';
  position: absolute;
  width: 1px;
  height: 110%;
  background: var(--grey);
  top: 0.5rem;
  right: calc((var(--spacing-40) / 2) * -1);
  animation: fadeIn 0.5s ease-in-out;
}

.container:last-of-type:after{
  content: '';
  width: 0;
  height: 0;
}

.list{
  display: flex;
  flex-flow: column wrap;
  gap: var(--spacing-12);
  font: var(--font-p1-desktop-default);
}
.item > a{
  cursor: pointer;
  transition: 0.2s color ease-in-out;
}

.label{
  font: var(--font-h2-mobile-default);
  font-size: 1.5rem;
}

.dark .label {
  color: var(--blue);
}

.dark .itemContent:hover{
  color: var(--black-alpha-7);
}

.dark .itemContent:active{
  color: var(--blue);
}

.light .itemContent {
  color: var(--white);
}

.light .label,
.light .itemContent:hover,
.light .itemContent:active {
  color: var(--beige);
}

@media (max-width: 1024px){
  .list{
    font: var(--font-p1-mobile-default);
  }
}

@media (max-width: 768px){
  .container::after{
    content: none;
  }
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}